// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-es-index-tsx": () => import("./../../../src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-fr-index-tsx": () => import("./../../../src/pages/fr/index.tsx" /* webpackChunkName: "component---src-pages-fr-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-eng-index-tsx": () => import("./../../../src/pages/it-eng/index.tsx" /* webpackChunkName: "component---src-pages-it-eng-index-tsx" */),
  "component---src-pages-it-it-index-tsx": () => import("./../../../src/pages/it-it/index.tsx" /* webpackChunkName: "component---src-pages-it-it-index-tsx" */),
  "component---src-pages-sv-index-tsx": () => import("./../../../src/pages/sv/index.tsx" /* webpackChunkName: "component---src-pages-sv-index-tsx" */),
  "component---src-pages-us-index-tsx": () => import("./../../../src/pages/us/index.tsx" /* webpackChunkName: "component---src-pages-us-index-tsx" */)
}

